export default {
  methods: {
    // đổi từ kiểu compareAtPrice thành Compare At Price
    convertFilterDataName(item) {
      let result;
      switch (item) {
        // Màn shipping
        case 'product_tag':
          result = 'Product tag';
          break;
        case 'product_type':
          result = 'Product type';
          break;
        case 'collection_title':
          result = 'Collection title';
          break;
        case 'not_contains':
          result = 'dose not contains';
          break;
        case 'starts_with':
          result = 'starts with';
          break;
        case 'ends_with':
          result = 'ends with';
          break;
        case 'not_equals':
          result = 'is not equal to';
          break;
        // Màn Product
        case 'collectionIds':
          result = 'Collection:';
          break;
        case 'price':
          result = 'Variant price';
          break;
        case 'hasImage':
          result = 'Has image:';
          break;
        case 'productType':
          result = 'Product type:';
          break;
        case 'tags':
          result = 'Tags:';
          break;
        case 'title':
          result = 'Title';
          break;
        case 'compareAtPrice':
          result = 'Variant compare at price is';
          break;
        case 'hasVariantImage':
          result = 'Has variant image:';
          break;
        case 'varintTitle':
          result = 'Variant title';
          break;
        case 'vendor':
          result = 'Vendors:';
          break;
        case 'greaterThan':
          result = 'more than:';
          break;
        case 'equal':
          result = 'equals to:';
          break;
        case 'lessThan':
          result = 'less than:';
          break;
        case 'contain':
          result = 'contains:';
          break;
        case 'notContain':
          result = `doen'nt contain:`;
          break;
        // Màn Order
        case 'status':
          result = 'Status:';
          break;
        case 'paymentStatus':
          result = 'Payment status:';
          break;
        case 'fulfillmentStatus':
          result = 'Fulfillment status:';
          break;
        // case 'orderName':
        //   result = 'Order name:';
        //   break;
        // case 'orderDate':
        //   result = 'Order date:';
        //   break;
        case 'orderCountry':
          result = 'Order country:';
          break;
        case 'productVendor':
          result = 'Product Vendor:';
          break;
        case 'productSku':
          result = 'Product Sku:';
          break;
        case 'customerEmail':
          result = 'Customer:';
          break;
      }
      return result;
    },
    convertOrderStatus(item) {
      console.log(item);
      let a = [];
      item.forEach(i => {
        if (i == 'partially_unfulfilled') {
          i = 'partially unfulfilled';
          a.push(i);
        } else if (i == 'partially_processing') {
          i = 'partially processing';
          a.push(i);
        } else if (i == 'unfulfilled') {
          a.push(i);
        } else if (i == 'fulfilled') {
          a.push(i);
        } else if (i == 'processing') {
          a.push(i);
        } else if (i == 'REFUNDED') {
          i = 'refunded';
          a.push(i);
        } else if (i == 'PARTIAL_REFUND') {
          i = 'partially refund';
          a.push(i);
        } else if (i == 'COMPLETED') {
          i = 'completed';
          a.push(i);
        } else if (i == 'open') {
          a.push(i);
        } else if (i == 'archived') {
          a.push(i);
        } else if (i == 'cancelled') {
          a.push(i);
        }
      });
      return a.join(', ');
    },
  },
};
