<template>
  <v-row no-gutters>
    <v-col v-if="searchable" cols="12" class="d-flex">
      <!-- <div
        v-if="searchable && !selectStatus && !selectFulfill"
        class="input-group input-group-flush input-group-merge"
        style="flex: 1"
      > -->
      <div v-if="searchable" class="pr-3 input-group input-group-flush input-group-merge mt-3" style="flex: 1">
        <v-text-field class="mt-0" v-model="$parent.searchString" :placeholder="`Search`" autocomplete="off" />
      </div>
      <div v-if="selectStatus || selectFulfill">
        <SelectStatus v-if="selectStatus" @getSelect="getSelect" />
        <SelectFulfill v-if="selectFulfill" @getSelect="getSelect" />
      </div>
      <template v-if="filterFields && filterFields.length">
        <!-- Filter Button -->
        <div class="mt-3">
          <v-btn outlined color="primary" @click.stop="drawer = !drawer" class="btn btn-sm btn-secondary">
            <h4>
              More Filters <template>{{ countFilter }}</template>
            </h4>
          </v-btn>
        </div>
      </template>
      <!-- Filter Modal -->
      <FilterMoreDrawer
        :isTable="true"
        v-if="filterFields && filterFields.length > 0"
        :drawer.sync="drawer"
        :fields="filterFields"
        :filter="filterData"
        :sync-filter-query="false"
        :callback="setTmpFilterData"
        :callbackDone="commitFilterData"
        :timezone="pagePreference.standardTimezone"
      />
    </v-col>
    <v-col v-if="filterData && filterData.length > 0" cols="12">
      <div class="d-flex flex-wrap">
        <div v-for="(i, k) in filterData" :key="i.field" style="width: max-content">
          <v-chip
            v-if="i.value != '' || typeof i.value == 'boolean'"
            @click:close="removeFilterData(k)"
            class="ma-2"
            close
          >
            <!-- filter name ex: product type, collection, order date, order number ...-->
            <span>{{ convertFilterDataName(i.field) }}</span>
            <!-- chỉ cho các filter có compare ex: more than, lower than -->
            <span class="ml-1" v-if="i.compare">{{ convertFilterDataName(i.compare) }}</span>
            <!-- cho v-model là array ex: checkbox ( 3 cái status ở màn order) -->
            <span class="ml-1" v-if="Array.isArray(i.value) && i.field !== 'orderName'">{{
              convertOrderStatus(i.value)
            }}</span>
            <!-- cho v-model là string và không có compare ex: các autocomplete chỉ select 1 option -->
            <span
              class="ml-1"
              v-if="
                !Array.isArray(i.value) &&
                  !i.compare &&
                  i.field !== 'hasImage' &&
                  i.field !== 'hasVariantImage' &&
                  i.field !== 'fulfillmentDate' &&
                  i.field !== 'orderDate'
              "
            >
              {{ i.nameDislay ? i.nameDislay : i.field == 'collectionIds' ? collectionName : i.value }}
              <!-- {{ i.nameDislay ? i.nameDislay : i.value }} -->
            </span>
            <!-- cho v-model là string và có compare ex: variant title, compare at price -->
            <!-- v-model là true, false ex: has image, has variant image -->
            <span
              class="ml-1"
              v-if="
                filterFields.find(f => f.field === i.field).type.name !== 'autocomplete' &&
                filterFields.find(f => f.field === i.field).type.name !== 'checkbox' && // do v-model có _ (PARTIAL_REFUND)
                filterFields.find(f => f.field === i.field).type.name !== 'order-name' && // từ đây xuống v-model kiểu khác
                  filterFields.find(f => f.field === i.field).type.name !== 'country' &&
                  filterFields.find(f => f.field === i.field).kind !== 'Obj' &&
                  filterFields.find(f => f.field === i.field).type.name !== 'email'
              "
              >{{ i.value }}</span
            >
            <!-- xử lý riêng do v-model kiểu khác -->
            <span class="" v-if="filterFields.find(f => f.field === i.field).field == 'orderName'">
              Order Number: {{ i.value[0] }} to {{ i.value[1] }}
            </span>
            <span class="" v-if="filterFields.find(f => f.field === i.field).field == 'orderDate'">
              Order Date: {{ i.value.startDate }} to {{ i.value.endDate }}
            </span>
            <span class="" v-if="filterFields.find(f => f.field === i.field).field == 'fulfillmentDate'">
              Fulfillment Date: {{ i.value.startDate }} to
              {{ i.value.endDate }}
            </span>
          </v-chip>
        </div>
      </div>
    </v-col>
    <!-- <v-col v-if="searchable" cols="12">
      <v-divider></v-divider>
    </v-col> -->
    <v-col cols="12" v-if="!$parent.isLoading && document">
      <div
        v-if="selectable.getIds() && selectable.getIds().length > 0"
        class="mb-2 resources-header--sticky d-flex align-center"
      >
        <div class="">
          <v-btn-toggle background-color="#dddd">
            <v-btn>
              <div class="custom-control custom-checkbox table-checkbox pointer pb-2">
                <input
                  @change="onchangeSelectable"
                  :checked="checkSelectAll()"
                  type="checkbox"
                  class="custom-control-input pointer"
                  name="ordersSelect"
                  :id="selectableId"
                />
                <!-- là label của số sản phẩm đã selected -->
                <label :class="'pointer custom-control-label '" :for="selectableId">&nbsp;</label>
              </div>
              <h3 v-if="isOnCountProducts">{{ selectable.getIds().length }} selected</h3>
              <h3 v-if="!isOnCountProducts">{{ numberProductDisplay }} selected</h3>
            </v-btn>
            <v-menu v-model="openList" offset-y v-if="actionItems && actionItems.length > 0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="openList = true" style="width: 220px" v-bind="attrs" v-on="on">
                  Action <i class="ml-3 fas fa-caret-down"></i>
                </v-btn>
              </template>
              <v-list>
                <!-- {{ selectable.getIds() }} -->
                <v-list-item
                  v-for="(item, index) in actionItems"
                  :key="index"
                  @click="callbackAction(item, selectable.getIds())"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <div>
                    <v-divider></v-divider>
                  </div>
                </v-list-item>
              </v-list>
            </v-menu>
            <!-- <div v-if="isOnCountProducts && countt" class="px-3" style="line-height: 48px">
              Select all {{ countt }} products across all pages
            </div> -->
          </v-btn-toggle>
        </div>
        <span
          @click="selectAllProducts()"
          v-if="
            countt >= 50 &&
              selectable.getIds().length >= 50 &&
              isOnCountProducts &&
              (resourceType == 'collections' || resourceType == 'products')
          "
          class="px-3 primary--text select-all-product"
          style="line-height: 48px"
        >
          Select all {{ countt }} products across all pages
        </span>
        <span
          @click="clearAllProducts()"
          v-if="isClearAll && (resourceType == 'products' || resourceType == 'collections')"
          class="px-3 primary--text select-all-product"
          style="line-height: 48px"
        >
          Clear All
        </span>
      </div>
      <v-divider v-if="selectable.getIds().length > 0"></v-divider>
      <ResourceTable
        :update-router-callback="updateRouterCallback"
        :update-quantity-callback="updateQuantityCallback"
        :callbackSelection="callbackSelection"
        :document="document"
        :selectable="selectable"
        :draggable="draggable"
        :columns="columns"
        :deleteResource="deleteResource"
        :routeName="routeName"
        :callback-action="callbackAction"
      />
    </v-col>
    <v-col v-if="$parent.isLoading" cols="12" class="d-flex justify-center mt-8">
      <!-- <page-loader /> -->
      <v-skeleton-loader width="100%" type="card"></v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<script>
import ResourceTable from './ResourceTable';
import SelectStatus from './SelectStatus';
import SelectFulfill from './SelectFulfill';
import Selectable from '@/helpers/selectable';
import $ from 'jquery';
import { log } from 'handlebars';
import utilities from '@/helpers/utilities';
import event from '@/plugins/event-bus';
// import resourceListMixin from '@/mixins/resource-list';
import { mapState } from 'vuex';
import convertName from '@/mixins/convert-data-name';
import { collectionApi } from '@/apis/collection';
export default {
  mixins: [convertName],
  props: {
    draggable: {
      type: Boolean,
      default: false,
    },
    resourceType: {
      type: String,
    },
    countt: {
      type: Number,
    },
    routeName: {
      type: String,
    },
    selectStatus: {
      type: Boolean,
      default: false,
    },
    selectFulfill: {
      type: Boolean,
      default: false,
    },
    deleteResource: {
      type: Function,
      default(data) {},
    },
    document: {
      type: Array,
      default() {
        return [];
      },
    },
    columns: {
      type: Object,
      default() {
        return {};
      },
    },
    callback: {
      type: Function,
      default(id) {},
    },
    callbackAction: {
      type: Function,
      default(action, ids) {},
    },
    callbackSelection: {
      type: Function,
      default(ids) {},
    },
    searchable: {
      default: false,
    },
    /**
     * Filter fields
     */
    filterFields: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * Filter data
     */
    filterData: {
      type: Array,
      default() {
        return [];
      },
    },

    /**
     * Filter data change callback
     */
    filterCallback: {
      type: Function,
      default(filterData) {
        console.log('Default filter callback handler. Receieved ' + JSON.stringify(filterData));
      },
    },

    updateRouterCallback: {
      type: Function,
      default(id) {
        return { name: 'home' };
      },
    },
    actionItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    updateQuantityCallback: {
      type: Function,
      default(data) {},
    },
  },
  components: {
    ResourceTable,
    SelectStatus,
    SelectFulfill,
  },
  computed: {
    // orderTo() {
    //   return this.tmpFilterData.filter(i => i.field == 'orderName').map(x => x);
    // },
    numberProductDisplay() {
      let a = this.countt - this.totalProductClicked;
      return a;
    },
    number() {
      return this.selectable.getIds().length;
    },
    countFilter() {
      let a = this.filterData.filter(i => i.value.length > 0 || i.value.length != '').length;
      if (a != 0) {
        return ' ' + a;
      } else {
        return '';
      }
    },
    isParentLoading() {
      return this.$parent.isLoading;
    },
    ...mapState({
      pagePreference: state => state.pagePreference.preference,
    }),
  },
  watch: {
    numberProductDisplay() {
      this.$parent.productQuantity = this.numberProductDisplay;
    },
    isParentLoading() {
      if (this.isParentLoading) {
        this.selectable.unselectAll();
      }
    },
  },
  data() {
    return {
      listCollection: [],
      collectionName: '',
      openList: false,
      utilities,
      valueFilter: '',
      // setSelect: false,
      // setNoSelect: false,
      dataQuery: '',
      dataSearch: '',
      listFilter: [],
      selectableId: 'abcsse',
      drawer: null,
      selectable: null,
      displayHeaderTable: false,
      tmpFilterData: [],
      showModalAddTagsProduct: true,
      // display khi select all
      isSelectAllProducts: false,
      // display số lượng sp và clear
      isOnCountProducts: true,
      isClearAll: false,
      // để khi tích chọn 1 sp bên dưới thì tổng sp thay đổi theo, chỉ để hiển thị
      totalProductClicked: 0,
    };
  },
  methods: {
    async getCollectionName() {
      let params = {
        limit: 10000,
      };
      try {
        let data = await collectionApi.get(params);
        this.listCollection = data.data.collections;
        // console.log(this.listCollection);
      } catch (e) {
        console.log(e);
        this.listCollection = [];
      }
      if (this.listCollection.length > 0) {
        this.convertCollectionIdToTitle();
      }
    },
    async convertCollectionIdToTitle() {
      let collectionID = this.filterData.find(i => i.field == 'collectionIds');
      // console.log(collectionID);
      let collection = this.listCollection.find(y => y._id == collectionID?.value);
      // console.log(collection);
      this.collectionName = collection?.title;
      // console.log(this.collectionName);
    },
    checkSelectAll() {
      if (this.selectable.isSelectAll()) {
        this.totalProductClicked = 0;
      }
      return this.selectable.isSelectAll();
    },
    // khi ấn text xóa tất cả sp
    clearAllProducts() {
      this.selectable.unselectAll();
      this.isClearAll = false;
      this.isOnCountProducts = true;
      this.totalProductClicked = 0;
      this.$emit('getType', '');
    },
    // khi ấn text chọn tất cả sp
    selectAllProducts() {
      this.isClearAll = true;
      this.isOnCountProducts = false;
      // this.totalProductClicked = 0;
      this.$emit('getType', 'all');
    },
    getSelect(data) {
      this.dataQuery = data;
      this.$router.replace({ query: this.dataQuery });
    },
    checkAllItem() {
      let ids = this.document.map(item => (item._id ? item._id : item.id));
      this.selectable.selectAll(ids);
    },
    onchangeSelectable() {
      if (this.selectable.getIds().length !== this.document.length) {
        this.selectable.selectAll();
        this.checkAllItem();
      } else {
        this.selectable.unselectAll();
        this.clearAllProducts();
      }
      event.$emit('countProduct');
      this.callbackSelection(this.selectable.getIds());
      // console.log(1111);
    },
    checkDrawer() {
      if (!this.drawer) {
        this.drawer = true;
      } else {
        this.drawer = null;
        this.drawer = true;
      }
    },
    removeFilterData(k) {
      this.tmpFilterData.splice(k, 1);
      this.commitFilterData();
      this.$emit('closeClicked', this.filterData[k].field);
    },
    // when filter change
    setTmpFilterData(filterData) {
      this.tmpFilterData = filterData;
    },
    handleScroll(event) {
      this.displayHeaderTable = window.scrollY > 220 ? true : false;
      this.openList = false;
      // Any code to be executed when the window is scrolled
    },
    commitFilterData() {
      this.filterCallback([...this.tmpFilterData]);
      // console.log(this.tmpFilterData);
      let query = JSON.parse(JSON.stringify(this.$route.query));
      // console.log(query);
      let checkEmptyQuery;
      if (this.tmpFilterData.filter(item => item.value.length > 0 && item.value.length != '' && item.kind != 'Obj')) {
        checkEmptyQuery = this.tmpFilterData.filter(item => item.value.length > 0 && item.value.length != '');
      }
      if (this.tmpFilterData.filter(item => item.kind != 'Obj')) {
        checkEmptyQuery = this.tmpFilterData;
      }
      let newQuery = JSON.stringify(checkEmptyQuery);
      // console.log(checkEmptyQuery);
      // console.log(this.$route);
      if (query._query !== newQuery) {
        query._query = newQuery;
        this.$router.replace({
          query,
        });
        // this.$emit('pushQuery', query);
      }
      if (this.tmpFilterData.length === 0) {
        this.$router.replace({
          ...query,
          _query: '',
        });
      }
      // console.log(checkEmptyQuery);
      // console.log(this.$route);
    },
  },
  created() {
    window.addEventListener('scroll', this.handleScroll);
    this.selectable = new Selectable(data => {
      this.$forceUpdate();
    });
    // console.log(this.filterData);
    this.getCollectionName();
  },
  mounted() {
    // this.selectable.setSum(this.document && this.document.length ? this.document.length : 0);
  },
};
</script>
<style lang="scss">
.resources-header--sticky {
  // width: 350px;
  height: 60px !important;
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 59px !important;
  z-index: 10 !important;
  // z-index: 1000 !important;
  // background: #dddd;
  // border-radius: 12px 12px 12px 12px;
  // box-shadow: 0 8px 24px 0 rgba(54, 62, 67, 0.1), 0 16px 40px 0 rgba(54, 62, 67, 0.1);
  // .v-card {
  //   height: 100%;
  // }
}
// .setPading {
//   padding-top: 12px;
//   margin-left: 16px;
// }
// .setPading2 {
//   padding-top: 4px;
//   margin-left: 16px;
// }
.listaction1 {
  display: none;
}
.select-all-product {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
// Select Action là 1 v-menu đc v-model với this.openList
</style>
