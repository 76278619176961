import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/personalizes/';

function get(params) {
  return storeRequest.get(path, {
    params: { ...params },
  });
}
function count(params) {
  return storeRequest.get(`${path}count`, {
    params: { ...params },
  });
}

function create(data) {
  return storeRequest.post(path, data);
}
function getById(id) {
  return storeRequest.get(path + id);
}
function getPresionalizeWithProduct(id) {
  return storeRequest.get(`${path}product/` + id);
}
function update(id, data) {
  console.log('data', data);
  return storeRequest.put(path + id, data);
}
function remove(id) {
  return storeRequest.delete(path + id);
}
function getFont(params) {
  return storeRequest.get(path + 'font', {
    params: { ...params },
  });
}
function uploadFont(data) {
  return storeRequest.post(path + 'font', data);
}
function removeFont(id) {
  return storeRequest.delete(path + 'font/' + id);
}
//Lấy 1 widget
//fulfillment of order

export const personalizesApi = {
  get,
  create,
  update,
  count,
  getById,
  getFont,
  uploadFont,
  getPresionalizeWithProduct,
  remove,
  removeFont,
};
