import Vuex from 'vuex';
import event from '@/plugins/event-bus';

class Selectable {
  constructor(callback, id) {
    this.id = id;
    event.$on('selectable-unselect-all', () => {
      this.unselectAll();
    });

    // Listen to self unselect event
    event.$on('selectable-unselect-all-' + id, () => {
      this.unselectAll();
    });

    this.store = new Vuex.Store({
      state: {
        ids: [],
        isSelectAll: false,
        sumPage: 0,
      },
      mutations: {
        /**
         * Set ids
         */
        setIds(state, ids) {
          state.ids = ids;
        },
        setSum(state, sum) {
          state.sumPage = sum;
        },
        /**
         * Push an id
         *
         * @return bool
         */
        pushId(state, id) {
          if (state.ids.indexOf(id) === -1) {
            state.ids.push(id);
            // console.log(state.ids);
            return true;
          }

          return false;
        },

        /**
         * Remove an id
         *
         * @return bool
         */
        removeId(state, id) {
          if (state.ids.indexOf(id) === -1) {
            return false;
          }
          state.ids.splice(state.ids.indexOf(id), 1);
          return true;
        },

        /**
         * Set select all
         */
        setSelectAll(state, bool) {
          state.isSelectAll = bool ? true : false;
        },
      },
    });
    this.callback = function() {
      this.store.commit('pushId', 'szdasdasd');
    };
  }
  getIds() {
    return this.store.state.ids;
  }
  isSelected(id) {
    return this.isSelectAll() || this.store.state.ids.indexOf(id) > -1 ? true : false;
  }
  isSelectAll() {
    // this.$emit('onCountProducts');
    return this.store.state.isSelectAll;
  }
  select(id, sum) {
    if (this.store.state.sumPage === 0 || !this.store.state.sumPage) {
      this.store.commit('setSum', sum);
    }
    if (this.store.state.ids.indexOf(id) === -1) {
      this.store.commit('pushId', id);
      if (this.store.state.ids && this.store.state.ids.length === this.store.state.sumPage) {
        this.store.commit('setSelectAll', true);
      }
    }
  }
  setSum(sum) {
    this.store.commit('setSum', sum);
  }
  unselect(id, sum) {
    // this.$emit('offCountProducts');
    if (this.store.state.sumPage === 0 || !this.store.state.sumPage) {
      this.store.commit('setSum', sum);
    }
    let idIndex = this.store.state.ids.indexOf(id);
    this.store.commit('setSelectAll', false);
    if (idIndex > -1) {
      this.store.commit('removeId', id);
    }

    // Unselect all too
    // if (this.isSelectAll()) {
    //   this.unselectAll();
    // }
  }

  /**
   * Select all
   */
  selectAll(ids) {
    this.store.commit('setSelectAll', true);
    // this.callback(this.getData(), ids);
    this.store.commit('setIds', ids);
  }

  /**
   * unselectAll
   */
  unselectAll() {
    this.store.commit('setIds', []);
    this.store.commit('setSelectAll', false);
  }
  getData() {
    return JSON.parse(
      JSON.stringify({
        ids: this.store.state.ids,
        isSelectAll: this.store.state.isSelectAll,
      }),
    );
  }
}
export default Selectable;
