import storeRequest from './request/storeRequest';
import STORAGE_NAME from '@/const/storage';

let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
storeRequest.setStoreId(storeId);
let path = '/stores/onlinestore/pages/';

function list(params) {
  return storeRequest.get(path, {
    params: { ...params },
  });
}

function get(id) {
  return storeRequest.get(path + id);
}

function create(menu) {
  return storeRequest.post(path, menu);
}

function update(menu) {
  return storeRequest.put(path + menu.id, menu);
}

function deleteById(id) {
  return storeRequest.delete(path + id);
}

export const pageService = {
  list,
  create,
  update,
  get,
  deleteById,
};
