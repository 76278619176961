import moment from 'moment';

class DateTime {
  format(dateTime, formatString) {
    let timeZone;
    let a = localStorage.getItem('timeZone');
    if (a != 'undefined') {
      timeZone = localStorage.getItem('timeZone');
    } else {
      timeZone = 'America/Los_Angeles';
    }
    // let timeZone = localStorage.getItem('timeZone') ? localStorage.getItem('timeZone') : 'America/Los_Angeles';
    return moment(String(dateTime))
      .tz(timeZone)
      .format(formatString);
  }
  formatUTC(dateTime, formatString) {
    return moment(String(dateTime))
      .utc()
      .format(formatString);
  }
}

export default new DateTime();
