<template>
  <div>
    <v-dialog v-model="onListKlaviyo" max-width="930" class="dialog">
      <v-card>
        <v-card-title class="text-h5"> Klaviyo feed files </v-card-title>
        <div class="close-icon" @click="onListKlaviyo = false">
          <v-icon>fas fa-times</v-icon>
        </div>
        <v-divider class="mb-3"></v-divider>
        <v-card-text class="content text-center">
          <div v-for="(item, ind) in total" :key="ind" class="d-flex justify-space-around py-3">
            <div cols="11" class="">{{ env }}{{ storeId }}/{{ feedId }}_{{ item - 1 }}.json</div>
            <div cols="1">
              <v-icon @click="copyURL1(`${env}${storeId}/${feedId}_${item - 1}.json`)" class="">far fa-copy</v-icon>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" outlined text @click="onListKlaviyo = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar1" :timeout="1500">Copied</v-snackbar>
  </div>
</template>

<script>
import STORAGE_NAME from '@/const/storage';
export default {
  data() {
    let storeId = localStorage.getItem(STORAGE_NAME.STORE_ID);
    return {
      total: 0,
      feedId: '',
      snackbar1: false,
      storeId: storeId,
      onListKlaviyo: false,
      env: `https://minio.lattehub.com/lattehub/`,
    };
  },
  created() {
    if (process.env.NODE_ENV !== 'production') {
      this.env = `https://minio.lattex.xyz/hiweb-development/`;
    }
  },
  methods: {
    copyURL1(text) {
      // var copyText = document.getElementsByClassName('text-copy');
      navigator.clipboard.writeText(text);
      // console.log(text);
      this.snackbar1 = true;
    },
  },
};
</script>

<style>
.dialog {
  position: relative;
}
.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
}
.content {
  max-height: 500px;
  overflow-y: auto;
}
</style>
